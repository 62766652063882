<template>
<div id="modalFormMail" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog s uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <p class="uk-article-meta">Якщо Ви маєте питання щодо роботи системи або бажаєте отримати інформацію щодо співпраці надішліть, будь ласка, повідомлення на нашу електрону адресу</p>
    <a class="hov" href="mailto:geo.kharkiv.notify@gmail.com" tabindex="-1">geo.kharkiv.notify@gmail.com</a>

  </div>
</div>

<div id="load" class="modalDialog" style="padding-left:calc(50% - 100px); padding-top:200px; overflow-y:auto; z-index:2000;position: fixed">

  <span uk-spinner="ratio: 7"></span>
</div>
<div class="uk-grid-collapse" uk-grid>
  <div class="uk-width-1-2 uk-visible@l">
    <SidebarStart />
  </div>
  <div class="uk-width-1-2@l uk-width-1-1@m uk-flex uk-flex-center uk-flex-middle" uk-height-viewport="expand: true">
    <div class="uk-panel uk-padding-remove">


			<div class="uk-width-medium uk-padding-small" uk-scrollspy="cls: uk-animation-fade">
			<!-- login -->
			
			<form class="toggle-class">
                <h1 class="uk-text-center uk-h3">Авторизація</h1>
				<fieldset class="uk-fieldset">
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
							<input class="uk-input uk-border-pill" required placeholder="логін" id='login' type="text" autocomplete="off">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
							
							<input class="uk-input uk-border-pill" required placeholder="пароль" id='password' type="password">
							
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
						<div id="select-city">
							<select id="city" class='uk-select'></select>
							</div>
						</div>
					</div>
					<div class="uk-margin-small">
						<label><input class="uk-checkbox" id='rememberMe' type="checkbox"> Запам’ятати мене</label>
					</div>
					<div class="uk-margin-bottom">
						<button type="button" class="uk-button uk-button-primary uk-border-pill uk-width-1-1" onclick='Login()'>Увійти</button>
						</div>

				</fieldset>
			</form>
			<!-- /login -->

			<!-- recover password -->
			<form class="toggle-class" hidden>
				<div class="uk-margin-small">
					<div class="uk-inline uk-width-1-1">
						<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
						<input class="uk-input uk-border-pill" id='login2' placeholder="Логін" required type="text" autocomplete="off">
					</div>
				</div>
                <div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
						<div id="select-city">
							<select id="city1" class='uk-select uk-width-1-1'></select>
							</div>
						</div>
					</div>
                <p class="uk-article-meta">Новий пароль буде відправлено на Вашу електрону адресу</p>
				<div class="uk-margin-bottom">
					<button  type="button"  class="uk-button uk-button-primary uk-border-pill uk-width-1-1" onclick='resetPassword()'>Відправити пароль</button>
				</div>
			</form>
			<!-- /recover password -->
			
			<!-- action buttons -->
			<div>
				<div class="uk-text-center">
				
					<div class="uk-clearfix">
						<div class="uk-float-right">
							<div class=""><a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">Забули пароль?</a></div>
						</div>
						<div class="uk-float-left">
							<div class="">
                                <a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" onclick="Reg()">Створити реєстр</a>
                            </div>
						</div>
					</div>			
					
			
					
					<a class="uk-link-reset uk-text-small toggle-class" data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden><span data-uk-icon="arrow-left"></span> Повернутись, щоб увійти</a>
				</div>
			</div>
			<!-- action buttons -->
			</div>



    </div>
  </div>
</div>

<div class="uk-position-small uk-hidden@l">
  <span class="uk-text-small uk-text-muted">© <script>
      document.write(new Date().getFullYear());
    </script> - Автоматизована система управління геоданими</span>
</div>
</template>


<script>
import SidebarStart from '@/views/SidebarStart';

export default {
  components: {
    SidebarStart
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'js/login.js';
    document.head.appendChild(script);
}
}
</script>
