<template>
<div id="modalFormBell" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true" >
  <div class="uk-modal-dialog m uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>

    <ul class="" uk-tab id='modalFormBellswitcher'>
      <li><a>Підписатись</a></li>
      <li class='uk-hidden'><a>Відписатись</a></li>
      <li class='superRootRole kharkiv'><a>Дані</a></li>
    </ul>
    <ul class="uk-switcher uk-margin">

      <li>
        <div class="">
          <p class="uk-article-meta">На Вашу електрону адресу будуть приходити повідомлення про зміни в адресному реєстрі</p>
          <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
            <div class="uk-width-1-1">
              <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>

                <div class=" uk-width-1-1">
                  <div class="uk-inline">
                    <span class="uk-form-icon" data-uk-icon="icon: user"></span>
                    <input class="uk-input necessarilytext Bell" required placeholder="ПІБ" id='BellName' type="text">
                  </div>
                </div>

                <div class=" uk-width-1-1">
                  <div class="uk-inline">
                    <span class="uk-form-icon" data-uk-icon="icon: mail"></span>
                    <input class="uk-input Bell email" placeholder="e-mail" id='BellEmail' type="text" autocomplete="off">
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <p><label><input class="uk-checkbox" type="checkbox" id="xBell" onchange="if ($('#xBell').prop('checked'))
			$('#Bellbtn').prop('disabled', false);
		else
			$('#Bellbtn').prop('disabled', true);
		"> Надаю згоду на обробку моїх персональних даних</label></p>
                </div>
                <div class="uk-width-1-1">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-primary " disabled onclick="POSTNotif()" id='Bellbtn' type="button">Надіслати заявку</button>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <div class="">
          <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
            <div class="uk-width-1-1">
              <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>
                <div class=" uk-width-1-1">
                  <div class="uk-inline">
                    <span class="uk-form-icon" data-uk-icon="icon: mail"></span>
                    <input class="uk-input email Bell2" placeholder="e-mail" id='BellEmail2' type="text" autocomplete="off">
                  </div>
                </div>
                <div class="uk-width-1-1">
                  <p class="uk-text-right">
                    <button class="uk-button uk-button-primary " onclick="DELETENotif()" id='' type="button">Надіслати заявку</button>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </li>
      <li class='superRootRole kharkiv'>
        <div class="">
          <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
            <table id='tblN' class="uk-table uk-table-middle uk-table-divider uk-table-small"></table>
          </div>
        </div>
      </li>
    </ul>

  </div>
</div>

<div id="modalFormMail" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog s uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <h1 class="uk-legend uk-heading-bullet">Надіслати повідомлення</h1>
    <p class="uk-article-meta">Ваше повідомлення буде направлено безпосередньо до надавача інформації</p>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>
          <div class=" uk-width-1-1">
            <div class="uk-inline">
              <span class="uk-form-icon" data-uk-icon="icon: user"></span>
              <input class="uk-input necessarilytext Mail" required placeholder="ПІБ" id='MailName' type="text">
            </div>
          </div>

          <div class=" uk-width-1-1">
            <div class="uk-inline">
              <span class="uk-form-icon" data-uk-icon="icon: mail"></span>
              <input class="uk-input email necessarilytext Mail" placeholder="e-mail" id='MailEmail' type="text" autocomplete="off">
            </div>
          </div>

          <div class=" uk-width-1-1">
            <div class="uk-inline">
              <span class="uk-form-icon" data-uk-icon="icon:  bookmark"></span>
              <input class="uk-input necessarilytext Mail" placeholder="Тема" id='MailTitle' type="text" autocomplete="off">
            </div>
          </div>
          <div class="uk-width-1-1">
            <textarea class="uk-textarea necessarilytext Mail" placeholder="Текст повідомлення" id='MailText' rows="5"></textarea>
          </div>
          <div class="uk-width-1-1">
            <p><label><input class="uk-checkbox" onchange="if ($('#xMail').prop('checked'))
			$('#Mailbtn').prop('disabled', false);
		else
			$('#Mailbtn').prop('disabled', true);
		" type="checkbox" id="xMail"> Надаю згоду на обробку моїх персональних даних</label></p>
          </div>
          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-primary " disabled onclick="sentMail()" id='Mailbtn' type="button">Надіслати</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="modalCardSecondaryAddress" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog l uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <h3 class="uk-card-title uk-margin-remove-bottom" id='TestAddressBySecondaryAddress'></h3>
    <p uk-margin class='registryRole'>
      <a class="uk-button uk-button-default" uk-tooltip="Додати нову вторинну адресу" onclick='getSecondaryAddress(0)'>Додати</a>
    </p>
    <div class="uk-text-center">
      <p id='emptySecondaryAddress'>Не знайдено</p>
      <div class="uk-overflow-auto">
        <div id="tableSecondaryAddress">
        </div>
      </div>
    </div>
  </div>
</div>

<div id="modalCardGETHistory" class="uk-modal-full" uk-modal="stack: true">
  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-text-center" style='min-height: 100%;'>
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-text-center uk-margin-bottom">

      <div class="toggle4">
        <button class="uk-button uk-button-text " type="button" uk-toggle="target: .toggle4" aria-expanded="true"><span class="uk-margin-small-right" uk-icon="triangle-right"></span>Показати фільтр</button>
      </div>

      <div class="toggle4" hidden="">
        <button class="uk-button uk-button-text " type="button" uk-toggle="target: .toggle4" aria-expanded="true"><span class="uk-margin-small-right" uk-icon="triangle-up"></span>Сховати фільтр</button>
        <div class="uk-placeholder">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-4@l uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Номер</label>
                <div class="uk-form-controls">
                  <input type='text' class='uk-input uk-width-1-1' id="HistoryNumber">
                </div>
              </div>
            </div>
            <div class="uk-width-1-4@l uk-width-1-2@s">
              <div class=" ">
                <label class="uk-form-label">Період</label>
                <div class="uk-form-controls">
                  <input type='text' class='uk-input uk-width-1-1' id='datesHistory'>
                </div>
              </div>
            </div>
            <div class="uk-width-1-4@l uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Адреса</label>
                <div class="uk-form-controls">
                  <input type='text' class='uk-input uk-width-1-1' id='HistoryAddress'>
                </div>
              </div>
            </div>
            <div class="uk-width-1-4@l uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Район</label>
                <div class="uk-form-controls">
                  <input type='text' class='uk-input uk-width-1-1' id='HistoryRegion'>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-margin-small uk-text-right">
            <a class="uk-button uk-button-default" onclick='emptysortHistoryModal()'>Скасувати</a>
            <a class="uk-button uk-button-default" onclick='sorttableHistoryModal()'>Шукати</a>
          </div>

        </div>

      </div>

    </div>
    <div class="uk-overflow-auto">
      <div id="tableHistory">
      </div>
    </div>
  </div>
</div>
<div id="modalCardGETDOC" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true" >
  <div class="uk-modal-dialog l uk-modal-body uk-margin-auto-vertical uk-text-center">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-text-center">
      <div class="toggle3">
        <button class="uk-button uk-button-text " type="button" uk-toggle="target: .toggle3" aria-expanded="true"><span class="uk-margin-small-right" uk-icon="triangle-right"></span>Показати фільтр</button>
      </div>

      <div class="toggle3" hidden="">
        <button class="uk-button uk-button-text " type="button" uk-toggle="target: .toggle3" aria-expanded="true"><span class="uk-margin-small-right" uk-icon="triangle-up"></span>Сховати фільтр</button>
        <div class="uk-placeholder">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Тип</label>
                <div class="uk-form-controls">
                  <select class="uk-select necessarilyselect uk-width-1-1" id="typefilter"></select>
                </div>
              </div>
            </div>
            <div class="uk-width-1-2@s">
              <div class=" ">
                <label class="uk-form-label">Період</label>
                <div class="uk-form-controls">

                  <input type='text' class='uk-input uk-width-1-1' id='dates'>
                </div>
              </div>
            </div>
            <div class="uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Номер</label>
                <div class="uk-form-controls">
                  <input type='text' class='uk-input uk-width-1-1' id='numberfilter'>
                </div>
              </div>
            </div>
            <div class="uk-width-1-2@s">
              <div class="">
                <label class="uk-form-label">Видавник</label>
                <div class="uk-form-controls">
                  <select class="uk-select necessarilyselect uk-width-1-1" id="authorityfilter"></select>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-margin-small uk-text-right">
            <a class="uk-button uk-button-default" onclick='emptysort()'>Скасувати</a>
            <a class="uk-button uk-button-default" onclick='sorttable()'>Шукати</a>
          </div>

        </div>

      </div>

    </div>
    <div class="uk-overflow-auto">
      <div id="tableSelectDoc">
      </div>
    </div>
    <button class="uk-button uk-button-primary " onclick="SELECTDOC()" type="button">Обрати</button>
  </div>
</div>
<div id="load" class="modalDialog " style="padding-left:calc(50% - 100px); padding-top:calc(50vh - 100px); overflow-y:auto; z-index:2000;position: fixed">
  <span uk-spinner="ratio: 7"></span>
</div>
<div id="modalCardAddDocument" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog m uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>

    <h1 class="uk-legend uk-heading-bullet" id="h2modalDocument"></h1>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>
          <div class="uk-width-1-4@m uk-width-1-2@s">
            <label class="uk-form-label required_uk">Тип</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Document" id="documentTypeId"></select>
            </div>
          </div>
          <div class="uk-width-1-4@m uk-width-1-2@s">
            <label class="uk-form-label required_uk">Видавник</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Document" id="authorityId"></select>
            </div>
          </div>
          <div class="uk-width-1-4@m uk-width-1-2@s">
            <label class="uk-form-label required_uk">Номер</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext Document" type="text" autocomplete="off" id="number">
            </div>
          </div>
          <div class="uk-width-1-4@m uk-width-1-2@s">
            <label class="uk-form-label required_uk">Дата</label>
            <div class="uk-form-controls">
              <input class="necessarilytext uk-input Document" type="date" id="issued">
            </div>
          </div>
          <div class="uk-width-1-4@m">
            <label class="uk-form-label required_uk">Статус</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Document" id="Documentstatus"></select>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label required_uk">Повна назва</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext Document" type="text" autocomplete="off" id="title">
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Заголовок</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea Document" rows='2' id="comment"></textarea>
            </div>
          </div>

          <!--<div class="uk-width-1-1">
      <label class="uk-form-label">Посилання</label>
      <div class="uk-form-controls">
       <input class="uk-input Document" type="text" autocomplete="off" id="documentUrl">
      </div>
     </div>-->
          <div class="uk-width-1-1">
            <label class="uk-form-label">ПІБ заявника</label>
            <div class="uk-form-controls">
              <input class="uk-input Document" type="text" autocomplete="off" id="applicantName">
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">ЄДРПОУ заявника</label>
            <div class="uk-form-controls">
              <input class="uk-input Document" type="text" autocomplete="off" id="applicantCode">
            </div>
          </div>

          <div id='loaddoc' class="uk-width-1-1 uk-card uk-card-primary uk-card-small uk-card-body">
            <label class="uk-form-label">Завантажити документ</label>
            <div id="fileupload" class="uk-placeholder uk-text-center">
              <div class="uk-width-1-1@s uk-margin-small-bottom" uk-form-custom>
                <div class="js-upload" uk-form-custom>
                  <input type="file" id="Fileupload">
                  <button class="uk-button uk-width-1-1  uk-button-default" type="button" tabindex="-1" id="file">Оберіть файл</button>
                </div>
              </div>

            </div>
          </div>

          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-primary " onclick="updateDocument()" type="button">Зберегти</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div id="modalInfo2" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true" style='animation-duration:500ms;
animation-name:uk-fade;'>
  <div class="uk-modal-dialog s uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid id='info2'>

    </div>
  </div>
</div>
<div id="modalInfo" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true" style='animation-duration:500ms;
animation-name:uk-fade;'>
  <div class="uk-modal-dialog m uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid id='info'>

    </div>
  </div>
</div>
<div id="modalCardDoc" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog l uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <h1 class="uk-legend uk-heading-bullet">Документи</h1>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-1">
        <p uk-margin>
          <a class="uk-button uk-button-default" uk-tooltip="Додати новий документ" onclick='getDocument(0)'>Додати</a>
        </p>
        <table class="uk-table uk-table-middle uk-table-divider uk-table-small">
          <thead>
            <tr>
              <th class="uk-width-expand"></th>
              <th class="uk-width-auto"></th>
            </tr>
          </thead>
          <tbody id="tbodyDocument">
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div id="modalCardAddAddress" class="uk-modal-full" uk-modal="stack: true">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>

    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-2@s" id="mapAddress" uk-height-viewport="offset-top: true"></div>
      <div class="uk-width-1-2@s uk-padding-large">
        <h1 class="uk-legend uk-heading-bullet" id="h2modalAddress"></h1>
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>
          <div class="uk-width-1-2@s">
            <label class="uk-form-label required_uk">Вулиця</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Address" id="streetId"></select>
            </div>
          </div>

          <div class="uk-width-1-4@s">
            <label class="uk-form-label required_uk">Номер</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext Address" type="text" autocomplete="off" id="addressNumber">
            </div>
          </div>
          <div class="uk-width-1-4@s">
            <label class="uk-form-label">Корпус</label>
            <div class="uk-form-controls">
              <input class="uk-input Address" type="text" autocomplete="off" id="block">
            </div>
          </div>
          <div class="uk-width-1-3@s">
            <label class="uk-form-label required_uk">Статус</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Address" id="status"></select>
            </div>
          </div>
          <div class="uk-width-2-3@s">
            <label class="uk-form-label required_uk">Район</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Address" id="districtId"></select>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <label class="uk-form-label">Кадастровий номер</label>
            <div class="uk-form-controls">
              <input class="uk-input Address" type="text" autocomplete="off" id="cadastre">
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <label class="uk-form-label required_uk">Індекс</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext Address" type="number" step='1' min='0' id="postCode">
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Опис</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea Address" rows='2' id="description"></textarea>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Службова примітка</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea Address" rows='2' id="operatorComment"></textarea>
            </div>
          </div>
          <div id='gridAddress' class="uk-width-1-1">
          </div>
          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-default deladdress superRootRole" onclick="deleteAddress()" type="button">Видалити</button>
              <button class="uk-button uk-button-primary registryRole" onclick="updateAddress()" type="button">Зберегти</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="modalCardGenerateDoc" class="uk-modal-full" uk-modal="stack: true">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>

    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>

      <div class="uk-width-1-2@s" id="mapDoc" uk-height-viewport="offset-top: true"></div>
      <div class="uk-width-1-2@s uk-padding-large">
        <h1 class="uk-legend uk-heading-bullet" id="h2modalAddress">Формування витягу з адресного реєстру</h1>
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>

          <div class="uk-width-1-1">
            <label class="uk-form-label">Адреса</label>
            <div class="uk-form-controls">
              <input class='uk-input uk-width-1-1' id="adrsearch" readonly />
            </div>
          </div>

          <div class="uk-width-1-1">
            <label class="uk-form-label">Заявник</label>
            <div class="uk-form-controls">
              <input list="orgs" class="uk-input uk-width-1-1" id="applicant"/>
              <datalist id="orgs">
                <option value="Департамент містобудування та архітектури"></option>
              </datalist>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Картинка</label>

            <!--button class="uk-button uk-button-primary registryRole" onclick='gendoc();' type="button">СКРИН</button>-->

            <div class="js-upload uk-text-center uk-padding-small uk-card-secondary uk-margin-bottom">
              <div uk-form-custom>
                <input type="file" id="inputFile">
                <button class="uk-button uk-button-text " type="button" tabindex="-1" id="file">Оберіть знімок або скопіюйте та натисніть CTRL+V</button>
              </div>
            </div>
            <div class="uk-form-controls" id='divimg' style=' margin-bottom: 30px;'>
              <img id='image' />
            </div>
          </div>
          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-primary registryRole" onclick='GenerateV()' type="button">Сформувати</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div id="modalCardAddSecondaryAddress" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog l uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-1">
        <h1 class="uk-legend uk-heading-bullet" id="h2modalSecondaryAddress"></h1>
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>

          <div class="uk-width-1-1">
            <label class="uk-form-label required_uk">Номер</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext SecondaryAddress" type="text" autocomplete="off" id="SecondaryAddressaddressNumber">
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <label class="uk-form-label required_uk">Статус</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 SecondaryAddress" id="SecondaryAddressstatus"></select>
            </div>
          </div>
          <div class="uk-width-1-1@s">
            <label class="uk-form-label required_uk">Тип</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 SecondaryAddress" id="SecondaryAddresstypeId"></select>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Опис</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea SecondaryAddress" rows='2' id="descriptionSecondaryAddress"></textarea>
            </div>
          </div>
          <div id='gridSecondaryAddress' class="uk-width-1-1">
          </div>

          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-default deladdress superRootRole" onclick="deleteSecondaryAddress()" type="button">Видалити</button>
              <button class="uk-button uk-button-primary registryRole" onclick="updateSecondaryAddress()" type="button">Зберегти</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div id="modalCardAddStreet" class="uk-modal-full" uk-modal="stack: true">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>

    <div class="uk-grid-collapse  uk-flex-middle" uk-grid>
      <div class="uk-width-1-2@s" id="mapStreet" uk-height-viewport="offset-top: true"></div>

      <div class="uk-width-1-2@s uk-padding-large">
        <h1 class="uk-legend uk-heading-bullet" id="h2modalStreet"></h1>
        <div class="uk-grid-small uk-grid-match uk-flex-top uk-margin" uk-grid>
          <div class="uk-width-2-3@l">
            <label class="uk-form-label required_uk">Назва</label>
            <div class="uk-form-controls">
              <input class="uk-input necessarilytext Street" type="text" autocomplete="off" id="name">
            </div>
          </div>
          <div class="uk-width-1-6@l uk-width-1-2@s">
            <label class="uk-form-label required_uk">Тип</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Street" id="typeId"></select>
            </div>
          </div>
          <div class="uk-width-1-6@l uk-width-1-2@s">
            <label class="uk-form-label required_uk">Статус</label>
            <div class="uk-form-controls">
              <select class="uk-select necessarilyselect uk-width-1-1 Street" id="Streetstatus"></select>
            </div>
          </div>

          <div class="uk-width-1-1">
            <label class="uk-form-label">Аліаси</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea Street" rows='2' id="aliasOne"></textarea>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Старі назви</label>
            <div class="uk-form-controls">
              <select class="uk-select uk-width-1-1 Street" multiple id="beforeIds"></select>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Нові назви</label>
            <div class="uk-form-controls">
              <select class="uk-select uk-width-1-1 Street" multiple id="afterIds"></select>
            </div>
          </div>
          <div class="uk-width-1-1">
            <label class="uk-form-label">Службова примітка</label>
            <div class="uk-form-controls">
              <textarea class="uk-textarea Street" rows='2' id="operatorCommentS"></textarea>
            </div>
          </div>

          <div class="uk-width-1-1" id='gridStreet'>
            <div class="uk-width-1-1" id='divattDoc'>
              <h4 class="uk-legend uk-heading-bullet uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">Документи <a class="uk-margin-small-left " onclick="OpenDocModal();" uk-tooltip="title: Додати документ; delay: 500" uk-icon="plus"></a></h4>
              <div class="uk-width-1-1">
                <table class="uk-table uk-table-middle uk-table-divider uk-table-small">
                  <thead>
                    <tr>
                      <th class="uk-width-expand"></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="tbodyDocuments">

                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1">
            <p class="uk-text-right">
              <button class="uk-button uk-button-default delstreet superRootRole" onclick="deleteStreet()" type="button">Видалити</button>
              <button class="uk-button uk-button-primary registryRole" onclick="updateStreet()" type="button">Зберегти</button>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!--HEADER-->
<CityHeader />
<!--/HEADER-->
<!-- LEFT BAR -->
<SidebarLeft />
<!-- /LEFT BAR -->
<!-- CONTENT -->
<div id="content" data-uk-height-viewport="expand: true">

  <div id="offcanvas-usage" class="uk-padding-small uk-offcanvas-aside">

    <div class="aside-second uk-offcanvas-bar-light ">

      <!--<button class="uk-offcanvas-close" type="button" uk-close></button>-->

      <div class="uk-grid-collapse uk-flex uk-flex-middle " uk-grid>
        <div class="uk-width-expand">
          <form class="uk-search uk-search-default uk-width-1-1" uk-tooltip="title: Для пошуку потрібно ввести не менше трьох символів назви вулиці; delay: 500; pos: right">
            <!--<span uk-search-icon></span>-->
            <input class="uk-search-input" type="text" autocomplete="off" oninput='hideShowIconSearch(this.value); searchStreet(this.value)' id='search' placeholder="Пошук вулиці">

            <a class="uk-form-icon uk-form-icon-flip uk-hidden" id='iconClose' onclick="hideShowIconSearch(''); $('#search').val('').trigger('oninput')" uk-icon="icon: close"></a>
            <a class="uk-form-icon uk-form-icon-flip" id='iconSearch' uk-icon="icon: search"></a>
          </form>
        </div>
        <!--<div >
			<a class="uk-margin-small-left" href="" uk-icon="search"></a>
		</div>-->
        <div class='registryRole'>
          <a class="uk-margin-small-left " id='addAdr' onclick="getStreet(0)" uk-tooltip="title: Додати вулицю; delay: 500" uk-icon="plus"></a>
        </div>
        <div class='uk-width-1-1 uk-text-right'>
          <span id='CountStreet' class='uk-text-meta uk-text-italic'></span>
        </div>
      </div>

      <div id="container333" class=" uk-margin-small aside-second-first uk-margin-top" style=" position:relative; top:0; bottom:0">

        <table class="uk-table uk-width-1-1 uk-table-small ">
          <thead class='uk-hidden'>
            <tr>
              <th class='uk-width-expand'></th>
              <th class='uk-preserve-auto'></th>
            </tr>
          </thead>
          <tbody id='tbodyStreet'>
          </tbody>
        </table>
      </div>
      <hr class="uk-margin-small">

      <h4 class="uk-heading-bullet uk-padding-small uk-margin-remove-top uk-padding-remove-top uk-padding-remove-bottom">Адреси<a class="uk-margin-small-left registryRole" id='addStr' onclick="getAddress(0)" uk-tooltip="title: Додати адресу; delay: 500" uk-icon="plus"></a></h4>
      <div id="container444" class=" aside-second-second" style=" position:relative; top:0; bottom:0;">
        <div id='tbodyAddress'>

        </div>
      </div>

    </div>
  </div>

  <div class="uk-container uk-container-expand uk-padding-remove">

    <!--<div id="divmap" ><div id="map" class="map"></div></div> -->
    <div id="divmap" style="height: calc(100vh - 64px);">

      <div id="map" class="map" style="height: 100%;">

        <div id="popup" class="ol-popup">
          <!--<a href="#" id="popup-closer" class="ol-popup-closer"></a>-->
          <div id="popup-content"></div>
        </div>
      </div>
    </div>

 
  </div>
</div>
  
</template>

<script>
import CityHeader from '@/views/CityHeader';
import SidebarLeft from '@/views/SidebarLeft';

export default {
  components: {
    CityHeader,
    SidebarLeft
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'js/city.js';
    document.head.appendChild(script);
  }
}
</script>