<template>
  <!--
  <nav>
    <router-link to="/">Home212</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  -->
  <router-view/>
</template>

<style>

</style>
