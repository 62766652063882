<template>

  <div id="modalFormMail" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
    <div class="uk-modal-dialog s uk-modal-body uk-margin-auto-vertical">
      <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
      <p class="uk-article-meta">Якщо Ви маєте питання щодо роботи системи або бажаєте отримати інформацію щодо співпраці надішліть, будь ласка, повідомлення на нашу електрону адресу</p>
      <a class="hov" href="mailto:geo.kharkiv.notify@gmail.com" tabindex="-1">geo.kharkiv.notify@gmail.com</a>
    </div>
  </div>
  <div id="load" class="modalDialog " style="padding-left:calc(50% - 100px); padding-top:calc(50vh - 100px); overflow-y:auto; z-index:2000;position: fixed">
    <span uk-spinner="ratio: 7"></span>
  </div>
  <!-- CONTENT -->
  <div data-uk-height-viewport="expand: true">
    <div class="uk-container uk-container-expand uk-padding-remove ">
      <div class="uk-grid-collapse " uk-grid>
        <div class="uk-width-1-2@s uk-box-shadow-small">
          <div class=" topbar ">
            <nav class="uk-navbar-container uk-padding uk-padding-remove-top uk-padding-remove-bottom" uk-navbar>
              <div class="uk-navbar-left">
                <ul class="uk-navbar-nav">
                  <div class="uk-margin-small-left left-logo uk-flex uk-flex-middle">
                    <a class="uk-link-reset" href="index.html">
                      <img class="custom-logo" src="" alt=""><span class="uk-text-bold citytitle ">Геоінформаційний портал</span>
                    </a>
                  </div>
                </ul>
              </div>
              <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                  <li><a onclick="showModal('modalFormMail')" data-uk-icon="icon:  mail" title="Контакти" data-uk-tooltip></a></li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="uk-card-body">
            <h1 class="uk-legend uk-heading-bullet">Інформація про адресний реєстр</h1>
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-1-1@m">
                <div class="uk-grid-small  uk-flex-middle" uk-grid>
                  <div class="uk-width-1-3@m uk-flex uk-flex-center">
                    <div class="uk-inline uk-width-expand" style='min-height: 60px' uk-tooltip="title: Оберіть логотип; delay: 500">
                      <div class='uk-text-center'><img id='logo' class="logo" src="" style="max-height:150px;" alt="" uk-img></div>
                      <div class="uk-overlay uk-overlay-default uk-placeholder uk-position-bottom uk-padding-small uk-margin-remove">
                        <div class="js-upload uk-text-center">
                          <div uk-form-custom>
                            <input type="file" id="inputFile">
                            <button class="uk-button uk-button-text " type="button" tabindex="-1" id="file"><span class="uk-margin-small-right" uk-icon="image"></span>Обрати логотип</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-2-3@m">
                    <label class="uk-form-label required_uk">Населений пункт</label>
                    <div class="uk-form-controls">
                      <input class="uk-input necessarilytext" type="text" autocomplete="off" id="cityName">
                    </div>
                    <label class="uk-form-label required_uk">Область</label>
                    <div class="uk-form-controls">
                      <input class="uk-input necessarilytext" type="text" autocomplete="off" id="regionName">
                    </div>
                  </div>
                  <div class="uk-width-1-1">
                    <label class="uk-form-label required_uk">Заголовок</label>
                    <div class="uk-form-controls">
                      <input class="uk-input necessarilytext" type="text" autocomplete="off" id="title">
                    </div>
                  </div>
                </div>
              </div>
              <h1 class="uk-legend uk-heading-bullet">Відповідальна особа</h1>
              <div class="uk-width-1-1@m">
                <label class="uk-form-label required_uk">ПІБ</label>
                <div class="uk-form-controls">
                  <input class="uk-input necessarilytext" type="text" autocomplete="off" id="responsiblePerson">
                </div>
              </div>
              <div class="uk-width-1-2@m">
                <label class="uk-form-label required_uk">Логін</label>
                <div class="uk-form-controls">
                  <div class="uk-inline" style="display:block;">
                    <a class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="icon: user"></a>
                    <input class="uk-input necessarilytext login uk-width-expand" type="text" autocomplete="off" id="login">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@m">
                <label class="uk-form-label required_uk">Пароль</label>
                <div class="uk-form-controls">
                  <div class="uk-inline" style="display:block;">
                    <a class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="icon: lock"></a>
                    <input class="uk-input necessarilytext password uk-width-expand" type="text" autocomplete="off" id="password">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@m">
                <label class="uk-form-label required_uk">Електрона адреса</label>
                <div class="uk-form-controls">
                  <div class="uk-inline" style="display:block;">
                    <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: mail"></a>
                    <input class="uk-input necessarilytext email uk-width-expand" type="text" autocomplete="off" id="email">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2@m">
                <label class="uk-form-label required_uk">Телефон</label>
                <div class="uk-form-controls">
                  <div class="uk-inline" style="display:block;">
                    <a class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="icon: receiver"></a>
                    <input class="uk-input necessarilytext uk-width-expand" type="text" autocomplete="off" id="phone">
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1">
                <p><input class="uk-checkbox" type="checkbox" id="x"> Надаю згоду на обробку моїх персональних даних</p>
              </div>
              <div class="uk-width-1-1">
                <p class="uk-text-right">
                  <button class="uk-button uk-button-primary disabled" disabled onclick="SaveRegister()" id='btn' type="button">Зареєструватись</button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2@s" uk-height-viewport="offset-top: true">
          <div class="uk-inline uk-width-expand">
            <div class="" id="map" uk-height-viewport="offset-top: true"></div>
            <div class="uk-overlay uk-overlay-primary uk-position-bottom uk-padding uk-overlay-primary-gradient">
              <p class="uk-text-bold uk-text-center uk-text-emphasis"><span class="uk-margin-small-right" uk-icon="icon:move;ratio:1.5;"></span>Позиціонуйте населений пункт на мапі за його межами</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  
  
  
  
</template>

<script>
  export default {
    name: 'GvRegister'
  }
</script>