<template>
    <aside id="left-col" class="uk-light  ">
      <div class="left-logo uk-flex uk-flex-middle content-box-dark">
        <!--<a class="uk-logo" href="#"><img class="custom-logo" src="/img/logo.png" alt="">АСУ геоданими</a>-->
        <a class="uk-link-reset uk-flex uk-flex-middle" href="index.html">
          <img class="custom-logo" src="" alt=""><span class="uk-text-bold citytitle uk-margin-small-left"></span>
        </a>
      </div>
    
      <div class="left-nav-wrap">
        <ul class="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
          <li class='uk-active'><a href="index.html"><span data-uk-icon="icon: search" class="uk-margin-small-right"></span>Адресний реєстр</a></li>
          <li><a href="zones.html"><span data-uk-icon="icon: polygon" class="uk-margin-small-right"></span>Об'єкти</a></li>
          <li class='auditRole'><a href="audit.html"><span data-uk-icon="icon: file-edit" class="uk-margin-small-right"></span>Аудит</a></li>
          <li class='dictionaryRole'><a href="dictionaries.html"><span data-uk-icon="icon: thumbnails" class="uk-margin-small-right"></span>Довідники</a></li>
          <li class='registryRole'><a href="documents.html"><span data-uk-icon="icon: album" class="uk-margin-small-right"></span>Документи</a></li>
    
          <li class='userRole'><a href="users.html"><span data-uk-icon="icon: users" class="uk-margin-small-right"></span>Користувачі</a></li>
          <!--<li  class='registryRole uk-disabled'><a href="#"><span data-uk-icon="icon: history" class="uk-margin-small-right"></span>Історія</a></li>-->
          <li class='superRootRole'><a href="import.html"><span data-uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>Імпорт</a></li>
          <li class='exportImportRole'><a href="export.html"><span data-uk-icon="icon: cloud-download" class="uk-margin-small-right"></span>Експорт</a></li>
    
        </ul>
    
        <div class="left-content-box uk-margin-top">
    
          <h5>Статистика</h5>
          <div>
            <span class="uk-text-small">Кількість вулиць <small id='countStreettext'></small></span>
            <progress class="uk-progress warning" value="0" id='countStreet' max="5000"></progress>
          </div>
          <div>
            <span class="uk-text-small">Кількість адрес <small id='countAddresstext'></small></span>
            <progress class="uk-progress success" value="0" id='countAddress' max="150000"></progress>
          </div>
          <div>
            <span class="uk-text-small">Кількість вторинних адрес <small id='countSecondaryAddresstext'></small></span>
            <progress class="uk-progress" value="0" id='countSecondaryAddress' max="150000000"></progress>
          </div>
    
        </div>
    
      </div>
      <div class="bar-bottom">
        <ul class="uk-subnav uk-flex uk-flex-center uk-child-width-1-5" data-uk-grid>
          <li class='alluser'>
            <a href="index.html" class="uk-icon-link" data-uk-icon="icon: home" title="Головна" data-uk-tooltip></a>
          </li>
          <li class='superRootRole'>
            <a href="register.html" class="uk-icon-link" data-uk-icon="icon: settings" title="Налаштування" data-uk-tooltip></a>
          </li>
          <li class='alluser'>
            <a href="myaccount.html" class="uk-icon-link" data-uk-icon="icon: user" id='iam' uk-tooltip="Обліковий запис" data-uk-tooltip></a>
          </li>
    
          <li class='alluser'>
            <a onclick="removetoken(); $(location).attr('href','index.html');" class="uk-icon-link" data-uk-tooltip="Вийти" data-uk-icon="icon: sign-out"></a>
          </li>
          <li class='user'>
            <a href="login.html" class="uk-icon-link" data-uk-tooltip="Вхід операторів системи" data-uk-icon="icon: sign-in"></a>
          </li>
        </ul>
      </div>
    </aside>    
</template>    