import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Register from '@/views/Register'
import CitiesForm from '@/views/CitiesForm'
import CityMain from '@/views/CityMain'
import LoginForm from '@/views/LoginForm'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },  
  {
    path: '/cities',
    name: 'cities',
    component: CitiesForm
  },  
  {
    path: '/city',
    name: 'ciy',
    component: CityMain
  },  
  {
    path: '/login',
    name: 'login',
    component: LoginForm
  },    
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
