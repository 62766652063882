<template>
<div id="modalFormMail" bg-close="false" class="uk-modal-container uk-flex-top" uk-modal="stack: true">
  <div class="uk-modal-dialog s uk-modal-body uk-margin-auto-vertical">
    <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
    <p class="uk-article-meta">Якщо Ви маєте питання щодо роботи системи або бажаєте отримати інформацію щодо співпраці надішліть, будь ласка, повідомлення на нашу електрону адресу</p>
    <a class="hov" href="mailto:geo.kharkiv.notify@gmail.com" tabindex="-1">geo.kharkiv.notify@gmail.com</a>

  </div>
</div>

<div id="load" class="modalDialog" style="padding-left:calc(50% - 100px); padding-top:200px; overflow-y:auto; z-index:2000;position: fixed">

  <span uk-spinner="ratio: 7"></span>
</div>
<div class="uk-grid-collapse" uk-grid>
  <div class="uk-width-1-2 uk-visible@l">
    <SidebarStart />
  </div>
  <div class="uk-width-1-2@l uk-width-1-1@m">
    <div class="uk-panel uk-panel-scrollable uk-padding-remove" uk-height-viewport="" style="min-height: calc(100vh);">
      <div class=" topbar " uk-sticky>
        <nav class="uk-navbar-container uk-padding-small uk-padding-remove-top uk-padding-remove-bottom" uk-navbar style="position: relative; z-index: 980;">

          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav">

              <div class=" left-logo uk-flex uk-flex-middle">
                <form class="uk-search uk-search-default uk-width-1-1">
                  <input class="uk-input uk-width-medium" type="text" autocomplete="off" oninput='hideShowIconSearch(this.value); searchcity(this.value)' id='search' placeholder="Пошук">

                  <a class="uk-form-icon uk-form-icon-flip uk-hidden" id='iconClose' onclick="hideShowIconSearch(''); $('#search').val('').trigger('oninput')" uk-icon="icon: close"></a>
                  <a class="uk-form-icon uk-form-icon-flip" id='iconSearch' uk-icon="icon: search"></a>
                </form>
              </div>
            </ul>
          </div>

          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">

              <li><a onclick="showModal('modalFormMail')" data-uk-icon="icon:  mail" title="Контакти" data-uk-tooltip></a></li>

            </ul>

          </div>

        </nav>

      </div>

      <div class="uk-padding">

        <div class="uk-child-width-1-3@xl uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-2@s uk-grid-match" uk-grid id='xxx' uk-scrollspy="cls: uk-animation-fade; delay: 250; repeat: false"></div>

      </div>
    </div>
  </div>
</div>

<div class="uk-position-small uk-hidden@l">
  <span class="uk-text-small uk-text-muted">© <script>
      document.write(new Date().getFullYear());
    </script> - Автоматизована система управління геоданими</span>
</div>
</template>


<script>
import SidebarStart from '@/views/SidebarStart';

export default {
  components: {
    SidebarStart
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'js/cities.js';
    document.head.appendChild(script);
}
}


</script>

