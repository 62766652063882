<template>
    <div class="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden uk-light" data-uk-height-viewport>
      <div class="uk-position-cover uk-overlay-secondary"></div>
      <div class="uk-width-xlarge uk-position-z-index">
        <div class=" uk-grid-small uk-flex uk-flex-middle" uk-grid="">
          <div class="uk-width-auto@m">
            <img class="uk-animation-stroke" width="75" height="75" alt="" data-src="/img/logo_l.svg" uk-img="" src="/img/logo_l.svg" uk-svg="stroke-animation: true">
          </div>
          <div class="uk-width-expand@m">
            <h1 class="uk-margin-top uk-margin-remove-top uk-h2">Автоматизована система управління геоданими</h1>
          </div>
        </div>
      </div>
    </div>
</template>