<template>
    <header id="top-head" class="uk-position-fixed">
      <div class="uk-container uk-container-expand uk-background-default topbar">
        <nav class="uk-navbar uk-dark" data-uk-navbar="mode:click; duration: 250" style='height: 64px'>
    
          <div class="uk-navbar-left  uk-text-nowrap uk-text-truncate">
            <div class="   uk-text-truncate">
              <a class="uk-icon-button uk-hidden@m" onclick="document.getElementById('left-col').toggleAttribute('hidden'); " data-uk-icon="icon: menu" title="Меню"></a>
              <a class="uk-icon-button" href="#offcanvas-usage" data-uk-icon="icon:more-vertical" title="Налаштування" uk-toggle></a>
              <span class="uk-text-bold maintitle uk-margin-small-left "></span>
    
            </div>
          </div>
          <div class="uk-navbar-right  uk-text-nowrap uk-text-truncate">
            <div class="uk-text-truncate">
              <a class="uk-link uk-margin-small-right" uk-tooltip="title: Оберіть місто" href='cities.html'><span uk-icon="icon: location;"></span><span class="citytitle "></span></a>
    
              <a onclick='openFormBell()' class="uk-icon-button kharkiv" uk-tooltip="title: Підписка" uk-icon="bell"></a>
              <a onclick='openFormMail()' class="uk-icon-button" uk-tooltip="title: Зворотній зв'язок" uk-icon="mail"></a>
              <a onclick='OpenHistoryModal()' class="uk-icon-button kharkiv" uk-tooltip="title: Реєстр оновлень адрес" uk-icon="history"></a>
              <a onclick="$(location).attr('href','API.html');" class="uk-icon-button kharkiv" uk-tooltip="title: Інформація для розробників" uk-icon="code"></a>
            </div>
          </div>
        </nav>
      </div>
    </header>
</template>